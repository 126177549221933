// Multiuser panel

$moderation-animation: 0.4s ease-in-out forwards;
$panel-height-mobile: 42px;

.multiuser-panel {
  border-radius: $border-radius-md;
  width: 270px;
  height: 62px;
  padding: 6px 6px 6px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  background: transparent;
  transition: all 0.6s ease;

  &.has-moderator {
    background: white;
    border-color: $gray-light-4;
  }

  &-wrapper {
    position: fixed;
    top: 12px;
    right: 82px;
    z-index: 999;
    display: flex;
    align-items: center;
    &.is-disabled {
      cursor: not-allowed;

      .start-button {
        pointer-events: none;
        cursor: not-allowed;
        &:after {
          content: '';
          width: calc(100% + 2px);
          height: 100%;
          position: absolute;
          top: 0;
          left: -1px;
          background: rgba(white, 0.5);
        }
      }
    }

    .bp4-tooltip2 {
      .bp4-popover2-content {
        margin: -18px 0 0 0;
      }
    }
  }

  &-main {
    font-size: 15px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  .timer {
    order: -1;
    flex-shrink: 0;
    display: block;
    font-size: 30px;
    letter-spacing: -0.9504px;
    line-height: 36px;
    min-width: 91px;
    font-weight: 400;
    padding-right: 12px;
  }

  .moderator {
    min-width: 0;
    padding-right: 6px;
    line-height: 1.2;
    &-title {
      font-size: 18px;
    }
  }

  .stop-button,
  .start-button {
    display: flex;
    align-items: center;
    border-radius: $border-radius-md;
    border: 1px solid transparent;
    box-shadow: none;
    color: white;

    .custom-icon {
      width: 30px;
      height: 30px;
      color: transparent;
      path {
        fill: white;
      }
    }
  }

  .start-button {
    background: $primary;
    font-size: 17px;
    position: absolute;
    width: 100%;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    padding: 0 20px;
    .custom-icon {
      margin-right: 12px;
    }
    .text {
      margin-top: 2px;
      white-space: nowrap;
    }

    &.start-moderation {
      animation: start-moderation $moderation-animation;
      .text {
        animation: disappear 0.2s ease forwards;
      }
      .custom-icon {
        animation: start-moderation-icon $moderation-animation;
      }
    }

    &.end-moderation {
      animation: end-moderation $moderation-animation;
      .text {
        animation: appear 0.8s ease forwards;
      }
      .custom-icon {
        animation: end-moderation-icon $moderation-animation;
      }
    }
  }

  .stop-button {
    flex-shrink: 0;
    background: $error;
    width: 48px;
    height: 48px;
    justify-content: center;
    margin-left: auto;
  }
}

// Users button

.users-button {
  position: relative;
  margin-left: 8px;
  .custom-icon {
    margin: 0;
  }
  .super-index {
    font-size: 9px;
    font-weight: $semibold;
    position: absolute;
    top: 12px;
    left: 31px;
    width: auto;
    min-width: 14px;
    height: 14px;
    border-radius: 20px;
    padding: 0 3px;
    background: $primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Phone portrait

@media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
  .multiuser-panel {
    height: $panel-height-mobile;
    width: 208px;
    padding: 4px 4px 4px 6px;
    &-wrapper {
      top: 10px;
      right: 59px;
      .users-button {
        width: $panel-height-mobile;
        height: $panel-height-mobile;
        min-width: $panel-height-mobile;
        min-height: $panel-height-mobile;
        margin-left: 6px;
      }
      .super-index {
        top: 4px;
        left: 21px;
      }
    }
    .timer {
      font-size: 20px;
      padding-right: 0;
      min-width: 62px;
      line-height: 1;
    }
    .moderator {
      line-height: 1.05;
      &-title {
        font-size: 15px;
      }
      &-name {
        font-size: 14px;
      }
    }
    .start-button,
    .stop-button {
      .custom-icon {
        width: 26px;
        height: 26px;
      }
    }
    .start-button {
      font-size: 15px;
      padding: 0 8px;
      .custom-icon {
        margin-right: 6px;
      }
      &.start-moderation {
        animation: start-moderation-mobile $moderation-animation;
      }
      &.end-moderation {
        animation: end-moderation-mobile $moderation-animation;
      }
    }
    .stop-button {
      width: 32px;
      height: 32px;
      border-radius: 10px;
    }
  }
}
