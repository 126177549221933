// Step list

.step-list-sidebar {
  position: relative;
  z-index: 2;
  padding: 0;
  min-width: 166px;
  flex: 0 0 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px -2px black;

  .step-list {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 4px;
    padding: 4px;
    scrollbar-width: thin;
  }
}

// Step thumbnail

.step-thumbnail {
  height: 120px;
  width: 150px;
  position: relative;
  padding: 0;
  transition: all $general-change;
  margin: 0;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px $gray-light-2;

  .background-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.dragging {
    border: 2px solid $gray-4;
    opacity: 0.5;
    .bp3-button {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 4px;
    -webkit-user-drag: none;
  }

  &:hover {
    box-shadow: 0 0 1px 2px rgba($primary, 0.5);
    cursor: pointer;
  }

  &:active {
    border-color: $primary;
  }

  &.selected {
    border-color: $primary;
    &.dragging {
      box-shadow: 0 0 0 1px $primary;
    }
  }

  &:hover {
    .delete-button-wrapper,
    .camera-button {
      opacity: 1;
    }
  }

  .delete-button {
    background: #fff;
    border: 1px solid $gray-light-3;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    min-width: 0;
    min-height: 0;
    padding: 0;
    svg {
      width: 12px;
      height: 12px;
    }
    &:hover {
      background: $gray-light-4;
    }

    &-wrapper {
      opacity: 0;
      position: absolute;
      top: -8px;
      right: -8px;
      transition: opacity $quick-change;
      .bp4-popover2-content {
        margin-top: -6px;
      }
    }
  }

  .thumbnail-actions {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    .bp4-tooltip2 {
      top: 10px;
      .bp4-popover2-content {
        margin: 0;
      }
    }
  }

  .camera-button {
    transition: opacity 0.2s ease-out;
    width: 26px;
    height: 26px;
    margin-left: 4px;
    opacity: 0;
    svg {
      width: 20px;
      height: 20px;
    }
    &:not(.is-disabled) {
      &:hover {
        background: white;
        svg {
          color: $warning;
        }
      }
    }
    &.is-disabled {
      &:hover {
        background: rgba($error, 0.04);
        svg {
          color: $error;
        }
      }
    }
  }

  .camera-saved {
    .bp3-icon,
    svg path {
      color: $warning;
      fill: $warning;
    }
    &-indicator {
      color: $warning;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      pointer-events: none;
    }
  }
}

.drag-drop-box {
  position: relative;
  padding: 4px;

  .drop-overlay {
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    display: block;
    border-radius: 12px;

    &.dragging {
      display: block;
      background: rgba(0, 0, 0, 0.08);
    }

    &.dropping {
      &.drop-high {
        box-shadow: inset 0px 16px 16px -16px rgba($gray-1, 0.2);
      }
      &.drop-low {
        box-shadow: inset 0px -16px 16px -16px rgba($gray-1, 0.2);
      }
    }
  }
}

// fix overlapping scrollbar in firefox
@-moz-document url-prefix() {
  .step-list {
    &.has-scroll {
      padding-right: 14px;
    }
  }
}
