// Toasts

.player-toast {
  top: 74px;
  right: -4px;
  width: 100%;
  max-width: 410px;
  margin-top: 8px;
  border-radius: 12px;
  background: $primary-light-4;
  color: $primary-dark-1;
  .bp3-button,
  .bp4-button {
    border-radius: 10px;
    &:hover {
      background: lighten($primary-light-4, 10%) !important;
    }
  }
  .custom-icon,
  svg {
    color: $primary-dark-1;
    color: 22px;
    height: 22px;
  }
  @media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
    top: 52px;
    max-width: 300px;
  }
}
