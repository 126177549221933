// Animations

// Transitions
$small-item-change: 0.3s ease;
$color-change: 0.3s;
$general-change: 0.3s ease-in-out;
$quick-change: 0.1s ease-in-out;
$scale-change: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

// Keyframes
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes appear-top {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-bottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center-subtle {
  0% {
    -webkit-transform: scale(0.96);
    transform: scale(0.96);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes start-moderation {
  0% {
    transform: translateX(0);
    opacity: 1;
    background: $primary;
    width: 270px;
    border-radius: 12px;
  }

  50% {
    transform: translateX(calc(100% - 82px) scale(0.8));
    opacity: 1;
    min-width: 100%;
    border-radius: 12px;
  }

  100% {
    transform: translateX(calc(100% - 164px)) scale(0.17, 0.79);
    background: $error;
    opacity: 1;
    pointer-events: none;
    border-radius: 25%;
  }
}

@keyframes start-moderation-mobile {
  0% {
    transform: translateX(0);
    opacity: 1;
    background: $primary;
    width: 270px;
    border-radius: 12px;
  }

  50% {
    transform: translateX(calc(100% - 82px) scale(0.8));
    opacity: 1;
    min-width: 100%;
    border-radius: 12px;
  }

  100% {
    transform: translateX(calc(100% - 123px)) scale(0.15, 0.79);
    background: $error;
    opacity: 1;
    pointer-events: none;
    border-radius: 25%;
  }
}

@keyframes end-moderation {
  0% {
    transform: translateX(calc(100% - 164px)) scale(0.17, 0.79);
    border-radius: 25%;
    background: $error;
  }

  50% {
    border-radius: 12px;
  }

  100% {
    transform: translateX(calc(0)) scale(1);
    background: $primary;
    border-radius: 12px;
  }
}

@keyframes end-moderation-mobile {
  0% {
    transform: translateX(calc(100% - 123px)) scale(0.15, 0.79);
    border-radius: 25%;
    background: $error;
  }

  50% {
    border-radius: 12px;
  }

  100% {
    transform: translateX(calc(0)) scale(1);
    background: $primary;
    border-radius: 12px;
  }
}

@keyframes start-moderation-icon {
  0% {
    transform: none;
  }

  100% {
    transform: scale(5.5, 1.2) translateX(61%);
  }
}

@keyframes end-moderation-icon {
  0% {
    transform: scale(5.5, 1.2) translateX(61%);
  }

  100% {
    transform: none;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0px;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    width: 0px;
  }

  100% {
    opacity: 1;
    width: auto;
  }
}
