@import '~/node_modules/@blueprintjs/core/lib/scss/variables';

// Blueprint Theme

// Override general blueprint styles

:focus {
  outline: none;
}

.bp3 {
  &-skeleton {
    transition: height $general-change;
  }

  &-toast {
    align-items: center;
    font-weight: $semibold;
    box-shadow: none;
    border-radius: $border-radius;
    box-shadow: none;

    .custom-icon {
      flex-shrink: 0;
    }

    &-container {
      padding: 0 16px 16px;
    }

    &-message {
      padding-left: 6px;
    }

    &[class*='bp3-intent-'] {
      svg {
        color: #fff;
      }
    }

    > svg {
      margin-left: 10px;
      color: #fff;
    }

    &.bp3-intent-primary {
      background-color: $primary;
    }

    &.bp3-intent-success {
      background-color: $success;
    }

    &.bp3-intent-warning {
      background-color: $warning;
    }

    &.bp3-intent-danger {
      background-color: $error;
    }

    .bp3-button {
      border: none !important;
      outline: none;
      align-self: flex-start;
    }
  }

  &-callout {
    font-size: 11px;
    line-height: 14px;
    font-weight: $regular;
    color: rgba($blue-dark-1, 0.8);
    margin-bottom: 6px;
    max-width: 300px;
    min-height: 38px;
    &.bp3-intent-warning {
      background: rgba($warning, 0.08);
    }
  }
  &-card {
    box-shadow: none;
    border: 1px solid $gray-light-4;
    border-radius: $border-radius;
  }

  // Slider

  &-slider {
    width: calc(100% - 20px);
    margin: 10px;
    &-handle {
      outline: none;
      border-radius: 50%;
    }
    &-progress {
      &.bp3-intent-primary {
        background-color: $primary;
      }
    }
  }

  // heading

  &-heading {
    font-weight: $bold;
  }

  // Dialog

  &-dialog {
    border-radius: $border-radius;
    padding: 24px;
    background: #fff;
    box-shadow: $elevation1;
    border: 1px solid $gray-light-3;
    .bp3-icon {
      margin-right: 16px;
    }
    p {
      margin-bottom: 10px;
    }

    svg {
      flex-shrink: 0;
    }

    .custom-icon {
      margin-right: 12px;
    }

    .bp3-callout {
      min-width: none;
    }

    .bp3-icon {
      background: transparent;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .bp3-button {
      &.bp3-intent-danger {
        background: $error;
      }
    }

    &.bp3-alert {
      svg {
        color: $error;
      }
      .custom-icon {
        color: $error;
      }
    }
  }

  // Overlay backdrop

  &-overlay-backdrop {
    background: rgba(0, 0, 0, 0.03);
  }

  // Control

  &-control {
    color: lighten($black-font, 30%);
    &-indicator {
      outline: none !important;
    }

    &:hover {
      input:checked {
        ~ .bp3-control-indicator {
          background: $primary;
        }
      }
      input {
        ~ .bp3-control-indicator {
          background: $gray-light-4;
        }
      }
    }

    &.bp3-switch {
      &:hover {
        input:checked {
          ~ .bp3-control-indicator {
            background: darken($primary, 10%);
          }
        }
      }
      input {
        &:checked {
          ~ .bp3-control-indicator {
            background: $primary;
          }
        }
      }
    }
    input:checked {
      ~ .bp3-control-indicator {
        background: $primary;
      }
    }
  }
  .bp3-control:hover
    input:checked
    ~ .bp3-control-indicator
    &-tab-indicator-wrapper {
    ~ .bp3-tab {
      outline: none;
    }
  }

  // Input

  &-input {
    box-shadow: none;
    border: 1px solid $gray-light-3;
    resize: none;
    border-radius: $border-radius;
    height: 36px;
    color: $gray-3;
    font-size: 12px;
    font-weight: $regular;
    &:focus {
      box-shadow: none;
      border-color: $gray-light-3;
    }
  }

  &-input,
  &-input-group {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $gray-3;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $gray-3;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $gray-3;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $gray-3;
    }
  }

  // File upload

  &-file-upload-input {
    box-shadow: none;
    border: 1px solid transparent;
    background: $gray-light-5;
    border-radius: 4px;
  }

  &-file-input {
    border-radius: $border-radius;
    input {
      width: 100%;
    }
  }

  // Button

  &-button {
    box-shadow: none;
    outline: none;
    transition: all $quick-change;
    border-radius: $border-radius;

    &.bp3-minimal {
      &.bp3-intent-primary {
        color: $primary;
        svg {
          color: $primary;
        }
        &:hover {
          color: $primary;
          background: lighten($primary, 48%);
        }
      }
      &.bp3-intent-danger {
        color: $error;
        svg {
          color: $error;
        }
      }
    }

    &:not(.bp3-intent-primary),
    &:not(.bp3-intent-success),
    &:not(.bp3-intent-warning),
    &:not(.bp3-intent-danger) {
      .bp3-icon {
        color: $gray-1;
      }
    }

    &:not([class*='bp3-intent-']) {
      &:hover {
        box-shadow: none;
      }
    }

    &.bp3-intent-primary,
    &.bp3-intent-success,
    &.bp3-intent-warning,
    &.bp3-intent-danger {
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }

    &.bp3-outlined {
      border-color: $gray-light-4;
      &.bp3-intent-primary {
        border-color: $primary;
        color: $primary;
        svg {
          color: $primary;
        }
        &:hover {
          color: $primary;
          background: lighten($primary, 48%);
        }
      }
      &.bp3-intent-danger {
        border-color: $error;
        color: $error;
        svg {
          color: $error;
        }
      }
    }

    &:not([class*='bp3-intent-']) {
      background: $gray-light-5;
      box-shadow: none;
      &:disabled,
      &.bp3-disabled {
        background: transparent;
        border: 1px solid transparent;
        color: darken($gray-light-1, 10%);
        svg {
          color: $gray-3;
        }
      }
    }
    &:hover {
      border-color: transparent;
      box-shadow: none;
      background: darken($gray-light-5, 3%);
    }
  }
  &-progress-bar {
    &.bp3-intent-primary {
      .bp3-progress-meter {
        background-color: $primary;
      }
    }
  }

  &-menu-item {
    &:hover {
      background-color: $gray-light-5;
    }
  }

  &-spinner {
    &.bp3-intent-danger {
      .bp3-spinner-head {
        stroke: $error;
      }
    }
  }

  &-form-group {
    margin-bottom: 8px;
  }

  // Non ideal state

  &-non-ideal-state {
    &-visual {
      font-size: 16px;
      margin-bottom: 10px;
      .bp3-icon svg {
        width: 40px;
        height: 40px;
        color: $gray-3;
        margin-bottom: 10px;
      }
      .custom-icon {
        width: 50px;
        height: 50px;
        color: $gray-3;
      }
    }
  }
}

// Menu item

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  background: $primary;
}

// Popover 2

.bp4-popover2 {
  box-shadow: $elevation1;
  animation: none;
  .bp4-popover2-arrow::before {
    box-shadow: $elevation1;
  }
  .bp4-popover2-arrow-border {
    fill: $gray-light-2;
  }
}
